/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

body {
  background: #e2e2e2;
  margin: 0;
  font-size: 100%;
  color: #485858;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.wrapper-desktop-zoom {
  transform: scaleX(1.1) scaleY(1.1) translate(-50%, -50%) translateZ(0) !important;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-filter: blur(0);
}

#wrapper {
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
  position: absolute;
  width: 56.25rem;
  height: 41.0625rem;
  left: 50%;
  top: 50%;
  background: white;
  font-size: 0.84375rem;
}
#wrapper .screen {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
}
#wrapper #spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
#wrapper #spinner img {
  position: absolute;
  width: 9.375rem;
  height: 9.375rem;
}
#wrapper .spinner-warning {
  width: 26.25rem;
  height: 26.25rem;
  position: absolute !important;
  /*display: none;*/
  z-index: 1000;
}
#wrapper .spinner-warning span {
  width: 100%;
  text-align: center;
  display: block;
  background: rgba(0, 0, 0, 0.5019607843);
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  color: white;
}

button {
  background: #ffc300;
  color: white;
  height: 2.5rem;
  text-transform: uppercase;
  border: none;
  transition: background 0.3s;
}
button:enabled:hover {
  background: rgb(234.6, 179.4, 0);
}
* > button:first-child:not(:last-child) {
  margin-right: 1.25rem;
}
button.red {
  background: red;
}
button.red:enabled:hover {
  background: rgb(234.6, 0, 0);
}
button.green {
  background: green;
}
button.green:enabled:hover {
  background: rgb(0, 107.6, 0);
}
button.blue {
  background: #1567ff;
}
button.blue:enabled:hover {
  background: rgb(0.6, 89.7487179487, 255);
}
button.gray {
  background: #485858;
}
button.gray:enabled:hover {
  background: rgb(62.82, 76.78, 76.78);
}
button:disabled {
  background: #dbdbdb;
  color: #afafaf;
}
button:disabled:enabled:hover {
  background: rgb(208.8, 208.8, 208.8);
}

label:not(.radio, .checkbox) {
  display: block;
  margin-bottom: 0.625rem;
}

input[type=text], input[type=email], input[type=password], input[type=number] {
  width: 100%;
  height: 2.5rem;
  border: 0;
}
input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=number]:focus {
  outline: 0;
}
.ng-submitted input[type=text].ng-invalid, .ng-submitted input[type=email].ng-invalid, .ng-submitted input[type=password].ng-invalid, .ng-submitted input[type=number].ng-invalid {
  border-color: red !important;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=radio], input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  display: none;
}
input[type=radio] + label, input[type=checkbox] + label {
  cursor: pointer;
}
input[type=radio]:checked + label, input[type=checkbox].fill:checked + label {
  background: white;
  color: #485858;
}
input[type=radio] + label, input[type=checkbox].fill + label {
  display: block;
  padding: 0.5rem 0 0.4375rem 0;
  text-align: center;
}
input[type=checkbox]:not(.fill) + label {
  position: relative;
  margin-left: 2.5rem;
  display: inline-block;
}
input[type=checkbox]:not(.fill) + label:after {
  content: "";
  position: absolute;
  top: -0.1875rem;
  left: -2.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/images/checkmark.svg) no-repeat;
  background-size: 0%;
  background-position: center;
  transition: background-size 0.1s;
}
input[type=checkbox]:not(.fill):checked + label:after {
  background-size: 70%;
}

.field {
  margin-bottom: 1.25rem;
}

.error {
  text-align: center;
  color: #ff2a39;
  font-size: 125%;
}

a {
  color: inherit;
  text-decoration: none;
}

.row {
  overflow: auto;
}

:not(:disabled)[ng-click], :not(:disabled)[ui-sref], :not(:disabled)[type=submit] {
  cursor: pointer;
}

.modal-window .modal-wrapper {
  position: absolute !important;
  visibility: hidden;
}
.modal-window.visible .modal-wrapper {
  visibility: visible;
}
.modal-window.visible .modal-wrapper .modal-content {
  opacity: 1;
}
.modal-window .modal-content {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition-property: opacity, transform, visibility;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.modal-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 198;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.modal-window.visible ~ .modal-overlay {
  opacity: 1;
  visibility: visible;
}

.admin-handle {
  color: gold;
  font-weight: bold;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, nav .logo img, nav .mute svg {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, nav .logo img, nav .mute svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, nav .logo img, nav .mute svg {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

nav {
  position: relative;
  height: 2.5rem;
  background: white;
}

nav > * {
  float: left;
}

nav .logo {
  width: 5rem;
  height: 100%;
  background: #434343;
}

nav .logo img {
  width: 1.6875rem;
  height: 1.6875rem;
}

nav .info {
  transform: translateY(-0.3rem);
  width: 48.75rem;
  height: 100%;
  font-weight: 600 !important;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

nav .info img {
  height: 1rem;
  padding-right: 0.5625rem;
  vertical-align: sub;
}

nav .info img:not(:first-child) {
  padding-left: 0.3125rem;
}

nav .info ul {
  list-style: none;
  padding: 0;
}

nav .info ul li {
  display: inline;
}

nav .info ul li:not(:first-child) {
  padding-left: 0.8125rem;
}

nav .info ul li a {
  text-decoration: underline;
}

nav .info ul li a:hover {
  text-decoration: none;
}

nav .info ul li slf-stars svg {
  vertical-align: sub;
}

nav .info ul.left {
  float: left;
}

nav .info ul.right {
  float: right;
}

nav .mute {
  width: 2.5rem;
  height: 100%;
  background: #eaecee;
}

nav .mute svg {
  width: 1.0625rem;
  height: 1.0625rem;
}

nav .mute.muted svg .waves {
  visibility: hidden;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

slf-modal-window .modal-wrapper {
  width: 22.5rem;
  z-index: 299;
}

slf-modal-window.visible .modal-content {
  transform: scale(1);
}

slf-modal-window .modal-content {
  padding: 2.5rem;
  transform: scale(0.7);
}

slf-modal-window .modal-content .message {
  font-size: 1.0625rem;
  text-align: center;
}

slf-modal-window .modal-content .buttons {
  margin-top: 2.5rem;
  overflow: auto;
  display: none;
}

slf-modal-window .modal-content .buttons button {
  /*@extend %col-8;*/
  width: 10rem;
  font-size: 0.8125rem;
}

slf-modal-window .modal-content .buttons button.decline {
  display: none;
}

slf-modal-window .modal-content .buttons button.confirm {
  display: block;
  margin: auto;
}

slf-modal-window .modal-content .buttons button.block {
  display: none;
}

slf-modal-window .modal-content img {
  display: none;
  margin: auto;
  margin-top: 2.5rem;
}

slf-modal-window .modal-alert .buttons, slf-modal-window .modal-confirm .buttons, slf-modal-window .modal-block .buttons {
  display: block;
}

slf-modal-window .modal-confirm .modal-content .buttons button {
  /*@extend %col-6;*/
  width: 7.5rem;
  display: inline;
}

slf-modal-window .modal-confirm .modal-content .buttons button.decline {
  float: left;
}

slf-modal-window .modal-confirm .modal-content .buttons button.confirm {
  float: right;
}

slf-modal-window .modal-confirm .modal-content .buttons button.block {
  display: none;
}

slf-modal-window .modal-block .modal-content .buttons button {
  /*@extend %col-4;*/
  width: 5rem;
  display: inline;
  margin-right: 0.4166666667rem !important;
  margin-left: 0.4166666667rem !important;
  font-size: 0.6875rem;
}

slf-modal-window .modal-block .modal-content .buttons button.decline {
  float: left;
}

slf-modal-window .modal-block .modal-content .buttons button.confirm {
  float: left;
}

slf-modal-window .modal-block .modal-content .buttons button.block {
  float: left;
}

slf-modal-window .modal-wait img {
  display: block;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

slf-popover-form .modal-wrapper {
  width: 20rem;
  z-index: 199;
  perspective: 1300px;
}

.modal-window.visible slf-popover-form .modal-content {
  transform: rotateY(0deg);
}

slf-popover-form .modal-content {
  padding: 1.25rem;
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
}

slf-popover-form .modal-content .close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.25rem;
}

slf-popover-form .modal-content h1 {
  margin-top: 0;
}

slf-popover-form .modal-content button {
  width: 100%;
  font-size: 1.125rem;
}

slf-popover-form .modal-content .forgot {
  text-align: right;
}

slf-popover-form .modal-content .success {
  text-align: center;
}

slf-popover-form .modal-content .field:last-child {
  margin-bottom: 0;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

slf-chat-log .messages, slf-chat-log .message-input {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

slf-chat-log .messages {
  height: 30.125rem;
  overflow-y: auto;
}

slf-chat-log .messages.smaller {
  height: 17.625rem;
}

slf-chat-log .messages > div.ng-enter {
  transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(0);
  transform-origin: left;
}

slf-chat-log .messages > div.ng-enter.self {
  transform-origin: right;
}

slf-chat-log .messages > div.ng-enter.ng-enter-active {
  transform: scale(1);
}

slf-chat-log .messages .message {
  margin-top: 0.5625rem;
  width: 11.25rem;
}

slf-chat-log .messages .message .user {
  position: relative;
  font-size: 0.75rem;
  color: white;
  margin-bottom: 0.125rem;
}

slf-chat-log .messages .message .user img {
  position: absolute;
  right: 0;
  top: 2px;
  width: 0.4375rem;
  height: 0.4375rem;
}

slf-chat-log .messages .message .content {
  position: relative;
  background: #f2f2f2;
  font-size: 0.8125rem;
  padding: 0.625rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 400;
  font-family: Industry, apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
}

slf-chat-log .messages .message .content:before {
  content: "";
  position: absolute;
  width: 0.6875rem;
  height: 0.5625rem;
  background: url("/assets/images/chat_message_tip.svg") no-repeat;
  left: -0.5rem;
}

slf-chat-log .messages .self .message {
  float: right;
}

slf-chat-log .messages .self .message .user {
  text-align: right;
}

slf-chat-log .messages .self .message .user img {
  display: none;
}

slf-chat-log .messages .self .message .content {
  background: #6483bd;
  color: white;
}

slf-chat-log .messages .self .message .content:before {
  background-image: url("/assets/images/chat_message_tip_self.svg");
  left: inherit;
  right: -0.625rem;
}

slf-chat-log .messages .clear {
  clear: both;
}

slf-chat-log .message-input {
  position: absolute;
  width: 100%;
  bottom: 2.25rem;
  display: -ms-flexbox;
  display: flex;
}

slf-chat-log .message-input input[type=text] {
  -ms-flex: 1;
      flex: 1;
  background: white;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 0.8125rem;
  color: black;
}

slf-chat-log .message-input input[type=text]:-ms-input-placeholder {
  color: #cecccc;
}

slf-chat-log .message-input input[type=text]::placeholder {
  color: #cecccc;
}

slf-chat-log .message-input button {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  padding: 0;
  vertical-align: top;
  width: 16.5%;
}

slf-chat-log .message-input button img {
  margin-top: 7px;
  width: 1.25rem;
}

slf-chat-log .message-input .unauthenticated {
  position: absolute;
  top: 0;
  left: 12.5%;
  right: 12.5%;
  width: auto;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-weight: bold;
}

slf-chat-log .message-input .unauthenticated span {
  position: absolute;
  text-align: center;
  width: 100%;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

slf-stars[data-step="0"] path {
  fill: #1567ff;
}

slf-stars[data-step="100"] path {
  fill: #ffc300;
}

slf-stars[data-step="500"] path {
  fill: green;
}

slf-stars[data-step="750"] path {
  fill: red;
}

slf-stars[data-step="1000"] path {
  fill: #3100de;
}

slf-stars[data-step="2000"] path {
  fill: #49de2b;
}

slf-stars[data-step="3000"] path {
  fill: #e10976;
}

slf-stars[data-step="4000"] path {
  fill: #7439e5;
}

slf-stars[data-step="5000"] path {
  fill: green;
  stroke: #FFFFFF;
}

slf-stars[data-step="6000"] path {
  fill: red;
  stroke: #FFFFFF;
}

slf-stars[data-step="7000"] path {
  fill: #7439e5;
  stroke: #FFFFFF;
}

slf-stars[data-step="8000"] path {
  fill: #34cbde;
  stroke: #FFFFFF;
}

slf-stars[data-step="9000"] path {
  fill: #ffc300;
  stroke: #FFFFFF;
}

slf-stars[data-step="10000"] path {
  fill: #000000;
  stroke: #ffc300;
  stroke-width: 2px;
}

slf-stars[data-step="12500"] path {
  fill: #000000;
  stroke: #ff51cc;
  stroke-width: 2px;
}

slf-stars[data-step="15000"] path {
  fill: #000000;
  stroke: #36ffac;
  stroke-width: 2px;
}

slf-stars[data-step="17500"] path {
  fill: #000000;
  stroke: #ebff47;
  stroke-width: 2px;
}

slf-stars[data-step="20000"] path {
  fill: #FFF;
  stroke: #5dbdff;
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 1px #4444dd);
}

slf-stars[data-step="22500"] path {
  fill: #FFF;
  stroke: #2a4ed7;
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 1px #4444dd);
  padding: 4px;
}

slf-stars[data-step="25000"] path {
  fill: #FFF;
  stroke: #4ccb5a;
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 1px #4444dd);
}

slf-stars[data-step="27500"] path {
  fill: #FFF;
  stroke: #d3284d;
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 1px #4444dd);
}

slf-stars[data-step="30000"] path {
  fill: #d3284d;
  stroke: #d3284d;
  stroke-width: 0px;
}

slf-stars[data-step="30000"] .bg {
  fill: none;
  stroke: #d3284d;
  stroke-width: 1px;
}

slf-stars[data-step="32500"] path {
  fill: #ba681e;
  stroke: #ba681e;
  stroke-width: 0px;
}

slf-stars[data-step="32500"] .bg {
  fill: none;
  stroke: #ba681e;
  stroke-width: 1px;
}

slf-stars[data-step="35000"] path {
  fill: #2c9945;
  stroke: #2c9945;
  stroke-width: 0px;
}

slf-stars[data-step="35000"] .bg {
  fill: none;
  stroke: #2c9945;
  stroke-width: 1px;
}

slf-stars[data-step="37500"] path {
  fill: #d4db11;
  stroke: #d4db11;
  stroke-width: 0px;
}

slf-stars[data-step="37500"] .bg {
  fill: none;
  stroke: #d4db11;
  stroke-width: 1px;
}

slf-stars[data-step="40000"] path {
  fill: #0d6e69;
  stroke: #0d6e69;
  stroke-width: 0px;
}

slf-stars[data-step="40000"] .bg {
  fill: none;
  stroke: #0d6e69;
  stroke-width: 1px;
}

slf-stars[data-step="42500"] path {
  fill: #3bff05;
  stroke: #3bff05;
  stroke-width: 0px;
}

slf-stars[data-step="42500"] .bg {
  fill: none;
  stroke: #3bff05;
  stroke-width: 1px;
}

slf-stars[data-step="45000"] path {
  fill: #9d5ae0;
  stroke: #9d5ae0;
  stroke-width: 0px;
}

slf-stars[data-step="45000"] .bg {
  fill: none;
  stroke: #9d5ae0;
  stroke-width: 1px;
}

slf-stars[data-step="47500"] path {
  fill: #c442cd;
  stroke: #cd428e;
  stroke-width: 0px;
}

slf-stars[data-step="47500"] .bg {
  fill: none;
  stroke: #cd428e;
  stroke-width: 1px;
}

slf-stars[data-step="50000"] path {
  fill: #fce77d;
  stroke: #fce77d;
  stroke-width: 0px;
}

slf-stars[data-step="50000"] .bg {
  fill: #F96167;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="52500"] path {
  fill: #df678c;
  stroke: #df678c;
  stroke-width: 1px;
}

slf-stars[data-step="52500"] .bg {
  fill: #3d155f;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="55000"] path {
  fill: #4a264e;
  stroke: #4a264e;
  stroke-width: 1px;
}

slf-stars[data-step="55000"] .bg {
  fill: #f1a17b;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="57500"] path {
  fill: #f4a896;
  stroke: #f4a896;
  stroke-width: 1px;
}

slf-stars[data-step="57500"] .bg {
  fill: #358596;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="60000"] path {
  fill: #f5c5c5;
  stroke: #f5c5c5;
  stroke-width: 1px;
}

slf-stars[data-step="60000"] .bg {
  fill: #c83030;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="62500"] path {
  fill: #faf0b8;
  stroke: #faf0b8;
  stroke-width: 1px;
}

slf-stars[data-step="62500"] .bg {
  fill: #224471;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="65000"] path {
  fill: #adefd1;
  stroke: #adefd1;
  stroke-width: 1px;
}

slf-stars[data-step="65000"] .bg {
  fill: #002003;
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="67500"] path {
  fill: #e562ee;
  stroke: #e562ee;
  stroke-width: 1px;
}

slf-stars[data-step="67500"] .bg {
  fill: rgb(255, 255, 255);
  stroke: none;
  stroke-width: 1px;
}

slf-stars[data-step="70000"] path {
  fill: #e57171;
  stroke: #e57171;
  stroke-width: 0px;
}

slf-stars[data-step="70000"] .bg {
  fill: black;
  stroke: #e57171;
  stroke-width: 1px;
}

slf-stars[data-step="72500"] path {
  fill: #ff9f05;
  stroke: #ff9f05;
  stroke-width: 0px;
}

slf-stars[data-step="72500"] .bg {
  fill: black;
  stroke: #ff9f05;
  stroke-width: 1px;
}

slf-stars[data-step="75000"] path {
  fill: #43da66;
  stroke: #43da66;
  stroke-width: 0px;
}

slf-stars[data-step="75000"] .bg {
  fill: black;
  stroke: #43da66;
  stroke-width: 1px;
}

slf-stars[data-step="77500"] path {
  fill: #d4db11;
  stroke: #d4db11;
  stroke-width: 0px;
}

slf-stars[data-step="77500"] .bg {
  fill: black;
  stroke: #d4db11;
  stroke-width: 1px;
}

slf-stars[data-step="80000"] path {
  fill: #6efdf2;
  stroke: #6efdf2;
  stroke-width: 0px;
}

slf-stars[data-step="80000"] .bg {
  fill: black;
  stroke: #6efdf2;
  stroke-width: 1px;
}

slf-stars[data-step="82500"] path {
  fill: #3bff05;
  stroke: #3bff05;
  stroke-width: 0px;
}

slf-stars[data-step="82500"] .bg {
  fill: black;
  stroke: #3bff05;
  stroke-width: 1px;
}

slf-stars[data-step="85000"] path {
  fill: #dbb9ff;
  stroke: #dbb9ff;
  stroke-width: 0px;
}

slf-stars[data-step="85000"] .bg {
  fill: black;
  stroke: #dbb9ff;
  stroke-width: 1px;
}

slf-stars[data-step="87500"] path {
  fill: #f67aff;
  stroke: #f67aff;
  stroke-width: 0px;
}

slf-stars[data-step="87500"] .bg {
  fill: black;
  stroke: #f67aff;
  stroke-width: 1px;
}

slf-stars[data-step="135000"] path {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 1px;
}

slf-stars[data-step="135000"] .bg {
  fill: black;
  stroke: black;
  stroke-width: 1px;
}

slf-stars[data-step="140000"] path {
  fill: #FFFFFF;
  stroke: #FFFFFF;
  stroke-width: 0px;
}

slf-stars[data-step="140000"] .bg {
  fill: black;
  stroke: #FFFFFF;
  stroke-width: 1px;
}

slf-stars[data-step="145000"] path {
  fill: greenyellow;
  stroke: #FFFFFF;
  stroke-width: 0px;
}

slf-stars[data-step="145000"] .bg {
  fill: black;
  stroke: black;
  stroke-width: 1px;
}

slf-stars[data-step="1000000"] path {
  fill: #FFFFFF;
  stroke: #FFFFFF;
  stroke-width: 1px;
}

slf-stars[data-step] svg {
  margin-right: 0.625rem;
}

slf-stars span {
  display: inline-block;
  min-width: 1.875rem;
}

.white-contrast-glow {
  filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.2));
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

slf-player-list {
  height: 12.375rem;
  margin-bottom: 0;
}

slf-player-list .head span {
  border: 2px solid white;
  padding: 0px 4px;
  margin-right: 1.25rem;
  font-size: 0.875rem;
}

slf-player-list .crown {
  float: left;
  width: 15px;
  height: 12px;
  padding: 0 1px 0 0;
  margin: 0;
}

slf-player-list .content {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  height: 9.875rem;
  overflow-x: hidden;
  overflow-y: auto;
}

slf-player-list .content table {
  width: 100%;
}

slf-player-list .content table tr td {
  overflow-x: hidden;
  overflow-y: hidden;
  line-height: 1;
  font-weight: 400;
  font-size: 0.8rem;
}

slf-player-list .content table tr td:nth-child(1) {
  width: 65%;
}

slf-player-list .content table tr td:nth-child(1) .mute {
  width: 0.4375rem;
  margin-left: 0.25rem;
}

slf-player-list .content table tr td:nth-child(2) {
  width: 35%;
}

slf-player-list .content table tr td:nth-child(2), slf-player-list .content table tr td:nth-child(3) {
  text-align: right;
}

slf-player-list .content table tr td slf-stars svg {
  vertical-align: sub;
}

slf-player-list .content table tr.ng-enter {
  transition: opacity 0.3s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  transform: translateX(100px);
}

slf-player-list .content table tr.ng-enter.ng-enter-active {
  opacity: 1;
  transform: translateX(0);
}

slf-player-list .content table tr.ng-leave {
  transition: opacity 0.3s, transform 0.2s;
  opacity: 1;
  transform: translateX(0);
}

slf-player-list .content table tr.ng-leave.ng-leave-active {
  opacity: 0;
  transform: translateX(100px);
}

slf-player-list .content table tr.self .mute {
  display: none;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, slf-character-animation {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, slf-character-animation {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, slf-character-animation {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

slf-character-animation {
  position: absolute;
}

slf-character-animation div {
  font-size: 12.5rem;
  color: white;
  text-transform: uppercase;
  transform: scale(0.5);
}

slf-character-animation.finished div {
  opacity: 0;
  visibility: hidden;
  animation-name: character-animation;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
}

@keyframes character-animation {
  0% {
    transform: scale(0.5);
    opacity: 1;
    visibility: visible;
  }
  20% {
    transform: scale(0.75);
  }
  75% {
    transform: scale(0.85);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    visibility: hidden;
  }
}
/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

slf-user-mute-dialog .box {
  position: absolute;
  z-index: 9;
}

slf-user-mute-dialog .box .head, slf-user-mute-dialog .box .content {
  text-align: center;
}

slf-user-mute-dialog .box .head .close {
  position: absolute;
  right: 1.25rem;
}

slf-user-mute-dialog .box .head .close img {
  width: 1rem;
  height: 1rem;
}

slf-user-mute-dialog .box .head .user {
  width: 1rem;
  vertical-align: sub;
  margin-right: 1.25rem;
}

slf-user-mute-dialog .box .content p {
  width: 60%;
  margin: auto;
  margin-top: 0.625rem;
}

slf-user-mute-dialog .box .content .buttons {
  margin-top: 1.125rem;
  overflow: auto;
}

slf-user-mute-dialog .box .content .buttons button {
  width: 6.5625rem;
  margin: 0;
}

slf-user-mute-dialog .box .content .buttons button:first-child {
  float: left;
}

slf-user-mute-dialog .box .content .buttons button:last-child {
  float: right;
}

emoji-picker {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 77px;
  right: 80px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.emoji-tab-bar {
  width: 100%;
  display: inline-block;
  background: linear-gradient(0deg, #e8e8e8, #f0f0f0, #fff);
  border-bottom: 1px grey solid;
}

.emoji-tab-bar-selected {
  background: linear-gradient(0deg, #fff, #f0f0f0, #e8e8e8) !important;
}

.emoji-tab-bar:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: -1;
  transform: scale(0.9);
  box-shadow: 0px 0px 8px 2px #000000;
}

.emoji-column, .emoji-tab-bar a {
  font-size: 20px;
  font-weight: 400;
  font-family: Industry, apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
  padding: 3px 6px 3px 5px;
  width: 16.5%;
  float: left;
}

.emoji-column a:first-child:hover, .emoji-tab-bar a a:first-child:hover {
  opacity: 1;
  animation: emoji-flash 1s infinite;
}

.emoji-list {
  position: relative;
  height: 0px;
  width: 200px;
  height: 200px;
  top: -21px;
  background-color: white;
}

.emoji-trigger {
  position: relative;
  right: -180px;
  top: 214px;
  width: 15px;
}
@keyframes emoji-flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0.5;
  }
}
/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper, .slf-splash .buttons button {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-splash {
  background-image: url("/assets/images/splash.jpg");
}

@media screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .slf-splash {
    background-image: url("/assets/images/splash@2x.jpg");
  }
}
@media screen and (min-resolution: 240dpi), screen and (min-resolution: 3dppx) {
  .slf-splash {
    background-image: url("/assets/images/splash@3x.jpg");
  }
}
.slf-splash h1 {
  margin-top: 6.875rem;
  text-transform: uppercase;
  font-size: 3.5625rem;
  color: white;
  letter-spacing: 12px;
  text-align: center;
  font-weight: 200 !important;
}

.slf-splash .logo {
  position: absolute;
  top: 1.25rem;
  left: 27.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.slf-splash .buttons {
  position: absolute;
  bottom: 2.5rem;
  left: 26.5%;
}

.slf-splash .buttons button {
  width: 12.5rem;
  font-family: "Industry Demi";
  font-weight: normal;
  font-size: 1.25rem;
  padding-top: 0px;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-game .canvas {
  position: absolute;
  width: 100%;
  height: 38.5625rem;
  overflow: hidden;
}

.slf-game .canvas .sidebar {
  position: absolute;
  width: 20rem;
  height: 100%;
  top: 1px;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.slf-game .canvas .sidebar.player-list-visible .player-list {
  display: block;
}

.slf-game .canvas .sidebar.player-list-visible .chat .messages {
  height: 18.75rem;
}

.slf-game .canvas .sidebar .box {
  margin: 1.25rem;
  color: white;
}

.slf-game .canvas .sidebar .box, .slf-game .canvas .sidebar .box .head {
  background: rgba(0, 0, 0, 0.5);
}

.slf-game .canvas .sidebar .box .head {
  height: 2.5rem;
  padding: 0.6875rem 1.25rem;
}

.slf-game .canvas .sidebar .box .content {
  padding: 1.25rem;
}

.slf-game .canvas .canvas-left {
  position: relative;
  width: 36.25rem;
  height: 100%;
  padding: 2.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-lobby {
  background-image: url("/assets/images/lobby.svg");
  /* xmas: background-image: url('/assets/images/xmas.jpg'); */
}

.slf-lobby .canvas-left .room-filter {
  margin-bottom: 1.25rem;
}

.slf-lobby .canvas-left .room-filter img {
  width: 1.125rem;
  height: 1.125rem;
}

.slf-lobby .canvas-left .room-filter ul {
  list-style: none;
  color: white;
  display: inline;
  padding-left: 1.25rem;
}

.slf-lobby .canvas-left .room-filter ul li {
  display: inline;
  font-weight: 600 !important;
  font-size: 1rem;
  text-transform: uppercase;
  opacity: 0.2;
  padding-right: 1.25rem;
}

.slf-lobby .canvas-left .room-filter ul li.active {
  opacity: 1;
}

.slf-lobby .canvas-left .rooms {
  height: 27.5rem;
  margin-bottom: 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.slf-lobby .canvas-left .rooms .room {
  position: relative;
  margin-bottom: 1.25rem;
}

.slf-lobby .canvas-left .rooms .room .left, .slf-lobby .canvas-left .rooms .room .right {
  position: relative;
}

.slf-lobby .canvas-left .rooms .room .left:before, .slf-lobby .canvas-left .rooms .room .right:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.25rem;
  content: "";
  z-index: 9;
}

.slf-lobby .canvas-left .rooms .room .left {
  /*@extend %col-22;*/
  width: 27.5rem;
  background: white;
  padding: 1.25rem;
  padding-top: 2.5rem;
}

.slf-lobby .canvas-left .rooms .room .left:before {
  background: #dde1e3;
}

.slf-lobby .canvas-left .rooms .room .left .name {
  font-weight: 200 !important;
  font-size: 1.625rem;
  color: #464646;
  margin-bottom: 1.25rem;
}

.slf-lobby .canvas-left .rooms .room .left .properties {
  list-style: none;
  padding: 0;
  padding-top: 0.625rem;
  margin: 0;
  color: #383838;
  border-top: 1px solid #dde1e3;
}

.slf-lobby .canvas-left .rooms .room .left .properties li {
  display: inline;
  font-weight: 500 !important;
  padding-right: 1.0625rem;
}

.slf-lobby .canvas-left .rooms .room .left .properties li .value {
  font-weight: 600 !important;
  padding-right: 0.3125rem;
}

.slf-lobby .canvas-left .rooms .room .left .properties li svg {
  vertical-align: sub;
}

.slf-lobby .canvas-left .rooms .room .right {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.75rem;
  height: 100%;
}

.slf-lobby .canvas-left .rooms .room .right:before {
  background: #ddac00;
}

.slf-lobby .canvas-left .rooms .room .right.forbidden:before {
  background: #bababa;
}

.slf-lobby .canvas-left .rooms .room .right.forbidden button {
  /* todo: button.disabled */
}

.slf-lobby .canvas-left .rooms .room .right button, .slf-lobby .canvas-left .rooms .room .right.forbidden button {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.125rem;
}

.slf-lobby .canvas-left .rooms .room .right button img, .slf-lobby .canvas-left .rooms .room .right.forbidden button img {
  margin-top: 0.625rem;
}

.slf-lobby .canvas-left .rooms .room.ng-enter {
  transition: transform 0.3s;
  transform: scaleY(0);
  transform-origin: top;
}

.slf-lobby .canvas-left .rooms .room.ng-enter.ng-enter-active {
  transform: scaleY(1);
}

.slf-lobby .canvas-left .rooms .room.ng-enter-stagger {
  transition-delay: 0.1s;
  transition-duration: 0s;
}

.slf-lobby .canvas-left .buttons {
  position: absolute;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-create-room {
  background-image: url("/assets/images/create_room.jpg");
}

@media screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .slf-create-room {
    background-image: url("/assets/images/create_room@2x.jpg");
  }
}
@media screen and (min-resolution: 240dpi), screen and (min-resolution: 3dppx) {
  .slf-create-room {
    background-image: url("/assets/images/create_room@3x.jpg");
  }
}
.slf-create-room :-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}
.slf-create-room ::placeholder {
  color: white;
  opacity: 0.7;
}

.slf-create-room .loadAndSaveBox {
  background: rgba(255, 255, 255, 0.1);
  padding-left: 1.25rem;
  padding-top: 0.3125rem;
  padding-bottom: 0px;
  margin-bottom: 1.25rem;
}

.slf-create-room #roomSaveButton {
  margin-top: 1.125rem;
}

.slf-create-room #roomSaveText {
  margin-top: 0.3125rem;
}

.slf-create-room .canvas-left {
  color: white;
}

.slf-create-room .canvas-left form {
  width: 36.25rem;
}

.slf-create-room .canvas-left .field {
  margin-right: 1.25rem;
}

.slf-create-room .canvas-left .field.private {
  padding-top: 36px;
}

.slf-create-room .canvas-left .field .dropdown {
  border: none;
  height: 2.5rem;
  width: 12.5rem;
}

.slf-create-room .canvas-left .categories div:nth-child(3n+1), .slf-create-room .canvas-left .category-number div:nth-child(3n+1) {
  margin-right: 0;
}

.slf-create-room .canvas-left .categories div:nth-child(3n) {
  /*@extend %col-7;*/
  width: 8.75rem;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label, .slf-room .canvas-left .info > div .value {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label, .slf-room .canvas-left .info > div .value {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label, .slf-room .canvas-left .info > div .value {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-room {
  background-image: url("/assets/images/room.svg");
  /* xmas: background-image: url('/assets/images/xmas_reduced.jpg'); */
}

.slf-room .canvas-left .info {
  position: relative;
  color: white;
  overflow: auto;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid white;
}

.slf-room .canvas-left .info > div {
  float: left;
  margin-right: 1.25rem;
}

.slf-room .canvas-left .info > div .value {
  margin-top: 0.5625rem;
}

.slf-room .canvas-left .info > div.name .value {
  /*@extend %col-11;*/
  width: 13.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slf-room .canvas-left .info > div.name span {
  text-decoration: underline;
}

.slf-room .canvas-left .info > div.character .value {
  /*@extend %col-2;*/
  width: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  text-transform: uppercase;
}

.slf-room .canvas-left .info > div.time .value {
  position: relative;
  /*@extend %col-4;*/
  width: 5rem;
}

.slf-room .canvas-left .info > div.time .value img {
  position: absolute;
  right: 0.5rem;
  width: 1.03125rem;
}

.slf-room .canvas-left .info > div.time .value.hurry {
  animation-name: pulsating-text;
}

.slf-room .canvas-left .info > div.time .value.hurry, .slf-room .canvas-left .info > div.time .value.hurry img {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.slf-room .canvas-left .info > div.time .value.hurry img {
  animation-name: hourglass;
}

.slf-room .canvas-left .info > div.totals .value {
  position: relative;
  width: 3.75rem;
  width: 3.95rem;
  text-align: center;
}

.slf-room .canvas-left .info > div.leave {
  position: absolute;
  bottom: 1.25rem;
  right: 0;
  margin-right: 0;
}

@keyframes hourglass {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(20deg);
  }
  20% {
    transform: rotateZ(-20deg) scale(1.25);
  }
  30% {
    transform: rotateZ(20deg) scale(1.5);
  }
  40% {
    transform: rotateZ(-20deg) scale(1.25);
  }
  50% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes pulsating-text {
  0% {
    color: white;
  }
  50% {
    color: red;
  }
  100% {
    color: white;
  }
}
.slf-room .canvas-left .remaining-height {
  height: 27rem;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-waiting div {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-waiting div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-waiting div {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

.slf-game-room-waiting div {
  color: white;
  font-size: 1rem;
  text-align: center;
  width: 70%;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-input .category .name span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-input .category .name span {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper, .slf-game-room-input .category .name span {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-game-room-input {
  height: 26.25rem;
  overflow: auto;
}

.slf-game-room-input .category {
  overflow: auto;
  margin-bottom: 0.625rem;
  background: #0b2640;
}

.slf-game-room-input .category .name {
  position: relative;
  min-width: 100%;
  min-height: 2.25rem;
  background: #dde1e3;
  color: #485858;
  padding-top: 0.75rem;
  float: left;
  overflow: hidden;
  font-size: 0.9375rem;
}

.slf-game-room-input .category .name span {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  display: inline-block;
  -ms-hyphens: auto;
  hyphens: auto;
}

.slf-game-room-input .category .word {
  /*@extend %col-25;*/
  width: 31.25rem;
  position: relative;
  background: white;
  float: right;
  font-size: 1.0625rem;
}

.slf-game-room-input .category .word input {
  width: 100%;
  font-size: 1.03125rem;
  padding-left: 1.25rem;
  padding-right: 2.5rem;
  color: #485858;
  text-align: center;
}

.slf-game-room-input .category .word input:disabled {
  background: #ccc;
}

.slf-game-room-input .category .word:before {
  position: absolute;
  content: "";
  background-size: cover;
  top: 9px;
  right: 0.625rem;
}

.slf-game-room-input .category:not(.editing).valid .word:before {
  width: 1.4375rem;
  height: 1.125rem;
  background-image: url(/assets/images/word_valid.svg);
}

.slf-game-room-input .category:not(.editing).invalid .word:before {
  width: 1.1875rem;
  height: 1.1875rem;
  background-image: url(/assets/images/word_invalid.svg);
}

.slf-game-room-input .category.editing .word:before {
  width: 1.375rem;
  height: 1.375rem;
  background-image: url(/assets/images/word_editing.svg);
}

.slf-game-room-input .category.ng-enter {
  transition: opacity 0.3s, transform 0.2s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  opacity: 0;
  transform: scale(1.5) translateY(-30px);
}

.slf-game-room-input .category.ng-enter.ng-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.slf-game-room-input .category.ng-enter-stagger {
  transition-delay: 0.05s;
  transition-duration: 0s;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-game-room-review .category {
  color: white;
  overflow: auto;
}

.slf-game-room-review .category .name {
  font-size: 1.3125rem;
  float: left;
}

.slf-game-room-review .voteRights {
  color: white;
  font-size: 1.03125rem;
}

.slf-game-room-review .words {
  margin-top: 1.25rem;
}

.slf-game-room-review .words .word {
  overflow: auto;
  margin: 0.125rem -1.25rem;
  padding: 0.5rem 1.25rem;
}

.slf-game-room-review .words .word.self {
  background: white;
}

.slf-game-room-review .words .word.self .value {
  color: #485858;
}

.slf-game-room-review .words .word.self .upvote, .slf-game-room-review .words .word.self .downvote {
  visibility: hidden;
}

.slf-game-room-review .words .word.upvoted .upvote, .slf-game-room-review .words .word.downvoted .downvote {
  opacity: 1 !important;
}

.slf-game-room-review .words .word .upvote:hover, .slf-game-room-review .words .word .downvote:hover {
  opacity: 0.7;
}

.slf-game-room-review .words .word > * {
  float: left;
}

.slf-game-room-review .words .word .value {
  /*@extend %col-15;*/
  width: 18.75rem;
  font-size: 1.03125rem;
  color: white;
}

.slf-game-room-review .words .word .upvote, .slf-game-room-review .words .word .downvote {
  height: 1.5rem;
  opacity: 0.4;
}

.slf-game-room-review .words .word .upvote {
  margin-right: 1.25rem;
}

.slf-game-room-review .words em {
  color: white;
  font-size: 16px;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-game-room-results table {
  width: 33.75rem;
  margin: 1.25rem -1.25rem;
  border-collapse: collapse;
}

.slf-game-room-results table tr {
  color: white;
  margin: 0.125rem 0;
  font-size: 1.03125rem;
  background: rgba(255, 255, 255, 0.3);
}

.slf-game-room-results table tr.self {
  background: white;
  color: #485858;
}

.slf-game-room-results table tr td {
  padding: 0.5rem 1.25rem;
  vertical-align: middle;
}

.slf-game-room-results table tr td.score {
  width: 20%;
  text-align: right;
}

.slf-game-room-results .category, .slf-game-room-results .categories {
  min-height: 1.875rem;
}

.slf-game-room-results .single-category .category {
  color: white;
  overflow: auto;
}

.slf-game-room-results .single-category .category .name {
  font-size: 1.3125rem;
  float: left;
}

.slf-game-room-results .single-category .category .next {
  float: right;
}

.slf-game-room-results .single-category .words tr .player {
  font-size: 1.03125rem;
  width: 30%;
}

.slf-game-room-results .single-category .words tr .word {
  width: 50%;
}

.slf-game-room-results .single-category .words tr .word .value {
  margin-right: 0.625rem;
}

.slf-game-room-results .single-category .words tr .word .upvote, .slf-game-room-results .single-category .words tr .word .downvote {
  height: 1.25rem;
  vertical-align: middle;
}

.slf-game-room-results .single-category .words tr .word .upvote.ng-enter {
  transition: opacity 0.2s, transform 0.2s;
  opacity: 0;
  transform: translateY(20px);
  transition-timing-function: ease-out;
}

.slf-game-room-results .single-category .words tr .word .upvote.ng-enter.ng-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.slf-game-room-results .single-category .words tr .word .downvote.ng-enter {
  transition: opacity 0.2s, transform 0.2s;
  opacity: 0;
  transform: scale(3);
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

.slf-game-room-results .single-category .words tr .word .downvote.ng-enter.ng-enter-active {
  opacity: 1;
  transform: scale(1);
}

.slf-game-room-results .single-category em {
  color: white;
  font-size: 15px;
}

.slf-game-room-results .scoring .total {
  font-size: 1.3125rem;
  float: left;
  color: white;
}

.slf-game-room-results .scoring .categories {
  text-align: right;
  color: white;
}

.slf-game-room-results .scoring .players {
  font-size: 1.125rem;
}

.slf-game-room-results .scoring .players .stars {
  width: 30%;
}

.slf-game-room-results .scoring .players .name {
  width: 50%;
}

.slf-game-room-results .single-category .words tr.ng-enter, .slf-game-room-results .scoring .players tr.ng-enter {
  transition: opacity 0.3s, transform 0.2s;
  opacity: 0;
  transform: scaleX(1.5);
}

.slf-game-room-results .single-category .words tr.ng-enter.ng-enter-active, .slf-game-room-results .scoring .players tr.ng-enter.ng-enter-active {
  opacity: 1;
  transform: scaleX(1);
}

.slf-game-room-results .single-category .words tr.ng-enter-stagger, .slf-game-room-results .scoring .players tr.ng-enter-stagger {
  transition-delay: 0.2s;
  transition-duration: 0s;
}

.slf-game-room-results .waiting {
  margin-top: 1.875rem;
  color: white;
  font-size: 14px;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Thin.woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Light.woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Book.woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Medium.woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Industry";
  src: url("/assets/fonts/Industry Bold.woff");
  font-style: normal;
  font-weight: 700;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  font-weight: 700;
}

@font-face {
  font-family: "Industry Demi";
  src: url("/assets/fonts/Industry Demi.woff");
  font-style: normal;
  font-weight: normal;
}
.font-family-default, body, button, input[type=text], input[type=email], input[type=password], input[type=number] {
  font-family: "Industry", sans-serif;
  font-weight: 500;
}

/*@use "variables";*/
#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#wrapper #spinner img, #wrapper .spinner-warning, .modal-window .modal-wrapper {
  transform: translateX(-50%) translateY(-50%);
}

#wrapper {
  box-shadow: 4px 8px 0px rgba(51, 51, 51, 0.04);
}

input[type=checkbox]:not(.fill) + label:after, input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  border: 2px solid white !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=radio] + label, input[type=checkbox].fill + label {
  padding: 0.375rem 0.75rem;
  font-size: 1.03125rem;
  background: transparent;
  color: white;
}

/* @use "../node_modules/bootstrap/scss/bootstrap.scss"; */
.row .col-25, .row .col-24, .row .col-23, .row .col-22, .row .col-21, .row .col-20, .row .col-19, .row .col-18, .row .col-17, .row .col-16, .row .col-15, .row .col-14, .row .col-13, .row .col-12, .row .col-11, .row .col-10, .row .col-9, .row .col-8, .row .col-7, .row .col-6, .row .col-5, .row .col-4, .row .col-3, .row .col-2, .row .col-1 {
  float: left;
}

.row .col-1 {
  width: 1.25rem;
}

.row .col-2 {
  width: 2.5rem;
}

.row .col-3 {
  width: 3.75rem;
}

.row .col-4 {
  width: 5rem;
}

.row .col-5 {
  width: 6.25rem;
}

.row .col-6 {
  width: 7.5rem;
}

.row .col-7 {
  width: 8.75rem;
}

.row .col-8 {
  width: 10rem;
}

.row .col-9 {
  width: 11.25rem;
}

.row .col-10 {
  width: 12.5rem;
}

.row .col-11 {
  width: 13.75rem;
}

.row .col-12 {
  width: 15rem;
}

.row .col-13 {
  width: 16.25rem;
}

.row .col-14 {
  width: 17.5rem;
}

.row .col-15 {
  width: 18.75rem;
}

.row .col-16 {
  width: 20rem;
}

.row .col-17 {
  width: 21.25rem;
}

.row .col-18 {
  width: 22.5rem;
}

.row .col-19 {
  width: 23.75rem;
}

.row .col-20 {
  width: 25rem;
}

.row .col-21 {
  width: 26.25rem;
}

.row .col-22 {
  width: 27.5rem;
}

.row .col-23 {
  width: 28.75rem;
}

.row .col-24 {
  width: 30rem;
}

.row .col-25 {
  width: 31.25rem;
}

.slf-highscore {
  background-image: url("/assets/images/room.svg");
  /* xmas: background-image: url('/assets/images/xmas_scores.jpg'); */
  color: white;
}

.slf-highscore .canvas-left {
  padding: 1.25rem;
}

.slf-highscore table {
  width: 100%;
  border-collapse: collapse;
}

.slf-highscore table .ranked {
  display: inline-block;
}

.slf-highscore table .ranked:before {
  transform: scale3d(0.2, 0.2, 1);
  position: absolute;
  display: block;
  margin: 5px 1px 0 0;
  transform-origin: 0px 0px;
  left: 79px;
}

.slf-highscore table .rank4_10:before {
  content: url("/assets/images/rank_4_10.png");
}

.slf-highscore table .rankGold:before {
  content: url("/assets/images/rank_gold.png");
}

.slf-highscore table .rankSilver:before {
  content: url("/assets/images/rank_silver.png");
}

.slf-highscore table .rankBronze:before {
  content: url("/assets/images/rank_bronze.png");
}

.slf-highscore table tr {
  margin: 0.125rem 0;
  font-size: 1.125rem;
  background: rgba(255, 255, 255, 0.3);
}

.slf-highscore table tr td {
  padding: 0.5rem 1.25rem;
  vertical-align: middle;
}

.slf-highscore table tr td.rank {
  text-align: center;
  width: 5%;
}

.slf-highscore table tr td.stars {
  text-align: right;
}

.slf-highscore table tr td.stars slf-stars svg {
  vertical-align: middle;
}

.slf-highscore .row {
  margin-top: 1.25rem;
}

.slf-highscore .row a:first-child {
  float: left;
}

.slf-highscore .row a:last-child {
  float: right;
}

